<template>
  <div class="base-input input-style relative">
    <v-text-field
      class="w-100"
      :class="isBudget ? 'base-budget' : ''"
      :type="type"
      outlined
      :placeholder="placeholder"
      dense
      height="44px"
      max-height="44px"
      :rules="rules"
      :hide-details="hideDetails"
      :value="value"
      :min="0"
      @input="$emit('input', $event)"
      :rounded="rounded"
      :append-icon="type == 'email' && hasValue ? 'mdi-check' :''"
      full-width
      color="register-primary"
      :readonly="readonly"
    >
    </v-text-field>
  </div>
</template>

<script>
import i18n from "../../i18n/index";
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    type: {
      type: String,
      default: "text",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    isBudget: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || i18n.t('forms.inputRequired')],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.input-style {
  .v-input__slot {
    border-radius: 10px;
    fieldset {
      background: $lightgreen !important;
      height: 49px !important;
      border: 1px solid $bordercolor !important;
      box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
    }
    .v-input__append-inner {
      margin-top: 5px !important;
    }
    .v-text-field__slot {
      height: 44px !important;
    }
  }
  .error--text {
    .v-input__slot {
      fieldset {
        height: 49px !important;
        border: 1px solid #e44358 !important;
      }
    }
  }
}
.base-budget input::-webkit-outer-spin-button,
.base-budget input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.base-budget input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
