<template>
    <div>
        <v-btn
                outlined class="fb-btn grey--text"
                elevation="0"
                height="40px"
                block
                :type="type"
                width="100px"
                :disabled="loading"
        >
      <span class="font-16 font-600" style="color: #38A894FF" v-if="!loading">{{
          title
          }}</span>
            <span v-if="loading"><loading-spinner></loading-spinner></span>
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
    },
};
</script>

<style lang="scss">
.fb-btn.v-btn--outlined {
  border: thin solid $register-primary;
}
</style>
