import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "vue-transitions-css";

Vue.use(Vuetify);
const opts = {
  rtl: localStorage.getItem("language") == "ar",
  theme: {
    themes: {
      light: {
        greytext: "#666666",
        blue: "#0067FF",
        lightgreen: "#F4F9FF",
        bordercolor: "#E0EEFF",
        darktext: "#333333",
        "register-primary": "#38A894",
        lightgreytext: "#999999",
        lightblue: "#33CBCC",
        purple: "#73ABFF",
        bord: "#373666",
        darkbord: "#202A4F",
        lightbord: "#83889F",
        "green-active": "#6DC9B8",
        bluebgdash: "#F8FAFF",
        bluebgdashlight: "#C9DFFF",
        bluetextdash: "#33588F",
        errorbg: "#FFCBCB",
        whitegreytext: "#BCBCBC",
        "lightregister-primary": "#C2EEE6",
        darkprimary: "#1D8371",
        yellowbgdash: "#FFEDBD",
        yellowtextdash: "#FFB800",
      },
    },
  },
};

export default new Vuetify(opts);
