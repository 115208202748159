import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    text: "",
    color: "",
    timeout: "",
    loading: false,
    userType: "",
    token: "",
    countries: "",
    cities: "",
    profile: {},
    profileNew: {},
    profileNewMedia: {},
    profileNewJob: 0,
    resetCode: false,
    profileDegrees: false,
    resendAvailable: false,
    remaining: 30,
    loaderAxios: false
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    loaderAxios(state) {
      return state.loaderAxios;
    },
    getLayoutPage(state) {
      return state.layoutPage;
    },
    userType(state) {
      return state.userType;
    },
    token(state) {
      return state.token;
    },
    countries(state) {
      return state.countries;
    },
    cities(state) {
      return state.cities;
    },
    doctorInfo(state) {
      return state.doctorInfo;
    },
    profile(state) {
      return state.profile;
    },
    profileNew(state) {
      return state.profileNew;
    },
    profileNewMedia(state) {
      return state.profileNewMedia;
    },
    profileNewJob(state) {
      return state.profileNewJob;
    },
    resetCode(state) {
      return state.resetCode;
    },
    profileDegrees(state) {
      return state.profileDegrees;
    },
    resendAvailable(state) {
      return state.resendAvailable;
    },
    remaining(state) {
      return state.remaining;
    },
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_LOADER_AXIOS(state, payload) {
      state.loaderAxios = payload;
    },
    SET_USER(state, payload) {
      localStorage.setItem("token", payload.token);
      if (payload.type == "doctor") {
        localStorage.setItem("role", "doctor");
        state.userType = "doctor";
      } else {
        localStorage.setItem("role", "clinic");
        state.userType = "clinic";
      }
      if (payload.type == "doctor") {
        router.go("/dashboard/interviews");
      } else {
        router.go("/dashboard/orders");
      }
      state.token = payload.token;
    },

    REMOVE_USER(state) {
      localStorage.removeItem("token");
      state.token = "";
      state.userType = "";
      router.go("/");
    },
    SET_ROLE(state, payload) {
      state.userType = payload;
    },
    SET_COUNTRIES(state, payload) {
      state.countries = payload;
    },
    SET_CITIES(state, payload) {
      state.cities = payload;
    },
    SET_DOCTOR_INFO(state, payload) {
      state.doctorInfo = payload;
    },
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    SET_PROFILE_NEW(state, payload) {
      state.profileNew = payload.doctor;
      state.profileNewMedia = payload.social_media;
      state.profileNewJob = payload.experience_length;
      state.profileDegrees = payload.degree_length;
    },
    SET_RESET_CODE(state, payload) {
      state.resetCode = payload;
    },
    TIME_VERIFY_HANDLER(state) {
      state.resendAvailable = false;
      if (!localStorage.endTime || localStorage.endTime <= 0) {
        state.remaining = 30;
      } else {
        state.remaining = +localStorage.endTime;
      }
      let timer;
      clearInterval(timer);
      timer = setInterval(() => {
        localStorage.endTime = state.remaining;
        state.remaining -= 1;
        if (state.remaining <= 0) {
          clearInterval(timer);
          localStorage.removeItem("endTime");
          state.resendAvailable = true;
        }
      }, 1000);
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    loaderAxios({ commit }, payload) {
      commit("SET_LOADER_AXIOS", payload);
    },
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    logout({ commit }) {
      commit("REMOVE_USER");
    },
    setRole({ commit }, payload) {
      commit("SET_ROLE", payload);
    },
    setCountries({ commit }, payload) {
      commit("SET_COUNTRIES", payload);
    },
    setCities({ commit }, payload) {
      commit("SET_CITIES", payload);
    },
    setDoctorInfo({ commit }, payload) {
      commit("SET_DOCTOR_INFO", payload);
    },
    setResetCode({ commit }, payload) {
      commit("SET_RESET_CODE", payload);
    },
    async setProfile({ commit }) {
      let url;
      if (localStorage.getItem("role") == "doctor") {
        url = "information";
      } else {
        url = "info";
      }
      let { data } = await axios.get(url);
      if (data && data.success) {
        // if(localStorage.getItem("role") == "doctor" && data.data.has_sign == 0){
        //   router.push('/upload-contract')
        // }else{
          commit("SET_PROFILE", data.data);
        // }
      }
    },
    async setProfileNew({ commit }) {
      let { data } = await axios.get("information_v2");
      if (data && data.success) {
        commit("SET_PROFILE_NEW", data.data);
      }
    },
    async resendCode({ dispatch }, payload) {
      let formData = new FormData();
      formData.append("email", payload.email);
      let res = await axios.post(payload.url, formData, {
        headers: {
          Accept: "application/json",
        },
      });
      if (res.success || res.status == 200) {
        dispatch(
          "showSnack",
          {
            text:  " The verification code has been sent", //vm.$t("global.verificationCode")
            color: "success",
          },
          { root: true }
        );
      } else {
        dispatch(
          "showSnack",
          {
            text: res.message,
            color: "error",
          },
          { root: true }
        );
      }
    },

    async timerHandler({ commit, state, dispatch }, payload) {
      if (state.resendAvailable) {
        await dispatch("resendCode", payload, { root: true });
      }
      commit("TIME_VERIFY_HANDLER");
    },
  },
  modules: {},
});
