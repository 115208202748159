var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.name)?_c('v-app',{staticClass:"app"},[(!_vm.$route.meta.dashoard)?_c('div',{class:_vm.$route.name != 'Registar' ? 'app-bg' : ''},[(
        _vm.$route.name != 'Registar' &&
        _vm.$route.name != 'Complete' &&
        !_vm.$route.meta.dashboard &&
        !_vm.$route.meta.completeProfile
      )?_c('navbar-app'):_vm._e(),_c('div',{staticClass:"min-height",class:_vm.$route.name == 'Registar' ? '' : 'app-custom'},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1),(
        _vm.$route.name != 'Registar' &&
        _vm.$route.name != 'Complete' &&
        !_vm.$route.meta.dashboard
      )?_c('footer-app'):_vm._e()],1):_vm._e(),_c('snackbar'),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.loaderAxios)?_c('loader'):_vm._e()],1),(_vm.$route.meta.dashoard)?_c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }