<template>
  <v-app class="app" v-if="$route.name">
    <div
      :class="$route.name != 'Registar' ? 'app-bg' : ''"
      v-if="!$route.meta.dashoard"
    >
      <navbar-app
        v-if="
          $route.name != 'Registar' &&
          $route.name != 'Complete' &&
          !$route.meta.dashboard &&
          !$route.meta.completeProfile
        "
      >
      </navbar-app>
      <div
        class="min-height"
        :class="$route.name == 'Registar' ? '' : 'app-custom'"
      >
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
      <footer-app
        v-if="
          $route.name != 'Registar' &&
          $route.name != 'Complete' &&
          !$route.meta.dashboard
        "
      ></footer-app>
    </div>

    <snackbar></snackbar>
    <transition name="fade" mode="out-in">
      <loader v-if="loaderAxios"></loader>
    </transition>
    <div v-if="$route.meta.dashoard">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </v-app>
</template>

<script>
import NavbarApp from "@/layout/Navbar";
import FooterApp from "@/layout/Footer";
import { mapGetters } from "vuex";
import Snackbar from "@/components/global/Snackbar";
import Loader from "./components/global/Loader.vue";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyA-Vpw7vr039qvZdvGlhG-mGpAMZwTd0kU",
  authDomain: "medicaljob-334013.firebaseapp.com",
  projectId: "medicaljob-334013",
  storageBucket: "medicaljob-334013.appspot.com",
  messagingSenderId: "115956055577",
  appId: "1:115956055577:web:3b144b572a9fd236e87488",
  measurementId: "G-92J6S8B05W",
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
getMessaging(app);

export default {
  name: "App",
  components: {
    NavbarApp,
    Snackbar,
    FooterApp,
    Loader,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["userType", "loaderAxios"]),
    token() {
      return localStorage.getItem("token");
    },
  },
  watch: {
    $route: {
      handler() {
        this.$store.dispatch("setRole", localStorage.getItem("role"));
      },
    },
  },
  methods: {
    handleNext() {
      this.$emit("next", this.obj);
    },
    async getCountries() {
      if (this.$route.name != "Sign Contract") {
        let { data } = await this.$axios.get("countries/global");
        this.$store.dispatch("setCountries", data.data);
      }
    },
  },
   mounted() {
    setTimeout(async() => {
        await this.getCountries();
    }, 400);
  },
};
</script>

<style lang="scss">
.app-bg {
  background: $bordercolor !important;
  overflow: hidden;
}
</style>
