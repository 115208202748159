export default {
    global: {
        welcome: 'Welcome To Medical Job',
        sign: 'To expedite the activation of your account,' +
            ' We kindly request your cooperation in completing the following steps:\n\n' +
            '1. Review the contract\n' +
            '2. Add your passport number\n' +
            '3. Provide a digital signature',

        hospitalSign: 'To expedite the activation of your account,' +
            ' We kindly request your cooperation in completing the following steps:\n\n' +
            '1. Review the contract.\n' +
            '2. Add missing information.\n' +
            '3. Provide a digital signature.',

        viewContract: 'View Contract ',
        signContract: 'Sign Contract ',
        upload: 'Upload',
        clear: 'Clear',
        download: 'Download',
        verificationCode: ' The verification code has been sent',
        GoToDashboard: 'Go to Dashboard',
        GoToHome: 'Go to Home',
        close: 'Close',
        generalError: 'Something went wrong',
        yes: "Yes",
        no: "No"
    },
    navbar: {
        home: "Home",
        howItWorks: "How it works",
        countries: "Countries",
        institutions: "Medical Institutions",
        professionals: "Medical Professionals",
        blogs: "Blogs",
        aboutUs: "About Us",
        contactUs: "Contact Us",
        createAccount: "Create Account",
        login: "Login/Register",
        viewDashboard: "View Dashboard",
    },

    homepage: {
        headerTitle: "Exposed New  Opportunities",
        headerDesc:
            "You are a few steps away from finding the perfect Professional match.",
        exploreRegion: " Explore the Medical Field in the MENA Region!",
        welcomeHome: "A Welcoming Home for Highly Qualified Medical Professionals!",
        showMoreCountries: "Show More Countries",
        seeYou: "We would love to see you smile",
        aboutDesc: `
            The agency, initially formed to help Lebanese doctors find jobs during the economic crisis, has since
            expanded its recruitment services across the MENA region. With a team of experienced recruiters, they
            have developed a modern system to simplify and streamline the recruitment process for both doctors
            and patients. Their mission is to assist skilled medical professionals in finding job opportunities, with a
            vision to become the leading medical recruitment platform in the region, offering seamless navigation
            and promising opportunities.
  `,
        clientRecommendations: "Client Recommendations",
        countriesTitle: "Explore the Quality of Life Index in the MENA & GCC Region!",
        countriesTitleDesc:
            "A Welcoming Home for Highly Qualified Medical Professionals!",
        // will translate
        showCountriesDetails: 'Show country details',

        exposedNew: "Get Exposed",
        opportunities: "To New Opportunities",
        getThePerfectJob: "Let’s start",
        showCountryDetails: "Show country details",
        countryDetailsTitle: "- Quality of Life Index",
    },

    completeProfile: {
        welcomeDescription: " We want you to be successful and find your dream job soon, and one of\n" +
            "        the most important tools for success is your profile. Over the years,\n" +
            "        we’ve found that the most successful candidates have profiles that are\n" +
            "        complete, accurate and paint a compelling picture of their services,\n" +
            "        skills, and accomplishments. Please Complete your profile for a better\n" +
            "        chance of recruitment.",
        start: "Start",
        getStarted: "Get Started",
        personalInfo: "Personal Info",
        jobExperience: "Job Experience",
        degrees: "Degrees",
        socialMedia: "Social Media",
        futureJob: "Future Job",
        contactInfo: "Contact Info"

    },

    aboutpage: {
        aboutus: "About Us",
        perfectJob: "Get the perfect job",
        whoWeAre: "Who We Are?",
        headDesc:
            "Our team comprises seasoned recruiters who have established strong relationships with numerous medical institutions throughout the MENA region. Our agency was initially inspired by assisting Lebanese medical professionals in securing employment in diverse Arab nations.",

        ourAim: "Our Aim",
        ourAimDesc:
            "Expand and empower our networks to assist qualified medical professionals in securing jobs in the MENA area by serving as a liaison between them and medical institutions abroad.",

        ourVision: "Our Vision",
        ourVisionDesc:
            "We aspire to become the leading medical recruitment platform in the region on which members can always depend to find promising opportunities.",

        ourMission: "Our Mission",
        ourMissionDesc:
            "Expose medical professionals to medical institutions by creating an effortless recruitment navigation system and by continuously expanding our network in the MENA region.",
    },

    userTypes: {
        joinOurNetwork: "Join Our Network!",
        signup: "Signup",
        joinUs: "Join us",
        professional: "Medical Professional",
        institution: "Medical Institution",
        asHospital: "As Medical Institution",
        asHospitalDesc:
            "Join us as a clinic or hospital and immediately start finding qualified doctors",
        asDoctor: "As Medical Professional",
        asDoctorDesc:
            "Join us as a doctor or nurse and immediately start finding your great job",
    },

    blogsPage: {
        ourBlogs: "Our Blogs",
        showMoreCountries: "Show More Countries",
    },

    getInTouch: {
        getInTouch: "Get in Touch with Our Team",
        howCanWeHelp: "How can we help you?",
        contactUsNow: "Contact us Now",
        fillForm: "Fill in the form and or drop an email 📨",
    },

    heroPage: {
        yourType: "Who are you?",
        loginNow: " Login now",
        signup: "Signup",
        asHospital: "As Medical Institution",
        asDoctor: "As Medical Professional",
        joinUs: "Join us",
        help: "Help",
        privacy: "Privacy",
        legal: "Legal",
        terms: "Terms",
        alreadyAccount: "Already have an account?",
    },

    howItWorks: {
        howItWorks: "How it works",
        medicalInstitutions: "Medical Institutions",
        medicalProfessionals: "Medical Professionals",
        firstStep: "First Step",
        secondStep: "Second Step",
        thirdStep: "Third Step",
        fourthStep: "Fourth Step",
        professionals: {
            firstStepSentense:
                "Register necessary details and upload degrees and/or work certification(s).",
            secondStepSentense:
                "After your account is verified by the platform, our team will start the recruitment process and look for an appropriate match for you among member medical institutions.",
            thirdStepSentense:
                "Track the progress of your application. After it has been selected by recruiters, sit for a job interview.",
            fourthStepSentense:
                "When the interview is completed and you have been accepted, upload all the necessary documents through our platform.",
        },
        institutions: {
            firstStepSentense:
                "Register necessary details and upload official company papers.",
            secondStepSentense:
                "After your account is verified by the platform, you can start requesting medical professionals’ profiles.",
            thirdStepSentense:
                "Medical Job team starts scanning profiles of appropriate candidates and notifies you once matches are identified.",
            fourthStepSentense:
                "Through our platform, you schedule video conferencing with selected candidates and change the hired professional’s status to “Accepted.”",
        },
    },

    forms: {
        inputRequired: 'Field is require',
        passwordValidation: 'Passwords must contain a minimum of 6 characters in length, including at least one uppercase letter and one numerical digit.',
        any: 'Any',
        done: 'Done',
        hospital: "Hospital",
        fullName: "Full Name",
        email: "Email",
        required: "field is required",
        emailRquired: "E-mail is required",
        emailValid: "E-mail must be valid",
        yourMessage: "Your message",
        typeMessage: "Type your message",
        send: "Send",
        createAccount: "Create Account",
        noAccount: "Don’t have an account?",
        forgetPassword: "Forget Password?",
        password: "Password",
        confirmPassword: "Confirm Password",
        login: "Login",
        createNewPassword: "Create your new password.",
        enterPassword: "Enter Password",
        createPassword: "Create Password",
        create: "Create",
        welcome: "Welcome 👋",
        createYourAccount: "Create your account",
        next: "Next",
        checkEmail:
            "A verification code has been sent to you. Please verify your email",
        verifyAccount: "Verify your account.",
        verificationCode: "Verification Code",
        enterVerificationCode: "Please enter your verification code",
        verify: "Verify",
        lastThing: "Last thing.",
        downloadContract: "Download contract",
        uploadFile: "Upload File",
        uploadSignedContract: "Upload Signed Contract",
        businessRegistrationDocuments: "Hospital/Clinic Business Registration Documents",
        clinic: "Clinic",
        doctor: "Doctor",
        representativeName: "Representative name",
        clinicName: "Medical Institution Name",
        medicalInstitutionType: "Medical Institution Type",
        fillForm: "Please fill up the form!",
        welcomeDoctor: "Welcome Doctor 👋",
        formAlso: "Please continue filling in your personal information ",
        phonenumber: "Phone number",
        phone: "Phone",
        country: "Country",
        countryCode: "Country Code",
        selectCoutry: "Select Coutry",
        city: "City",
        selectCity: "Select City",
        edit: "Edit",
        addNew: "Add New",
        jobExperience: "Job Experience",
        Institute: "Institute",
        jobPosition: "Job Position",
        fromDate: "From Date",
        require: "require",
        toDate: "To Date",
        save: "Save",
        add: "Add",
        discard: "Discard",
        cancel: "Cancel",
        specialities: "Specialities",
        gender: "Gender",
        nationality: "Nationality",
        assignedAt: "Assigned at",
        birthDate: "Birth Date",
        firstName: "First Name",
        lastName: "Last Name",
        logout: "Logout",
        continue: "Continue",
        update: "Update",
        doctorRecommendedCountry: "Future Job Locations",
        expectedSalary: "Expected salary usd",
        countryOfResidence: "Country of Residence",
        futureJobType: "Future Job Type",
        male: "Male",
        female: "Female",
        search: "Search",
        sendMessage: "Send Message",
        customer: "Customer",
        addOrDrag: "Press here to add more docs or drag",

        // will translate
        addNewRequest: "Add New Request",
        specializations: "Specializations",
        healthcareProviderType: "Healthcare Provider Type",
        experience: "Experience",
        jobType: "Job type",
        jobUrgency: "Job Urgency",
        educationLevel: "Education level",
        countryOfEducation: "Country of education",
        numberOfPositionNeeded: "Number of position needed",
        additionalInformation: "Additional information",
        dataCheck: "Data Check",
        yes: "YES",
        no: "NO",
        howDidYouHead: "How did you hear about us?",
        name: "Name",
        editPersonalInformation: "Edit Personal Information",
        futureJobLocation: "FutureJob Location",
        expectedMonthlySalary: "Expected Monthly Salary In USD",
        delete: "Delete",

        fieldOfStudyMajor: "Field Of Study Major",
        universityOrInstitutionCountry: "University Or Institution Name",
        graduationDateFrom: "Graduation Date From",
        graduationDateTo: "Graduation Date To",
        degree: "Degree",
        editDegree: "Edit Degree",
        wantToDelete: "Do you want to delete this ?",
        cv: "CV",
        contactType: "Contact Type",
        degreeFile: "Degree File",
        portfolio: "portfolio",
        editSocialMediaLinks: "Edit Social Media Links",
        editContactInfo: "Edit Contact Info",
        selectAll: "Select All",
        completeProfileHint: "Click here to update your profile",
        otherNationality: "Other Nationality (Optional)",
        yearsOfExperience: "Years Of Experience",
        saveContinue: "Save & Continue",
        message: "Message",
        clickToView: 'Click to view the file.',
        passportNum: 'Passport number',
        drawToSign: 'Sign it, Electronically using your mouse or finger',
        number: 'Number',
        dataCheckCountry: 'Data check country',
        dataCheckFile: 'Data check file',
        signerName: 'Signer name',
        signerPosition: 'Work Position',
        companyRegistrationNumber: 'Company registration number',
        skipAndUploadLater: 'Skip & Upload Later',
        codeResend: 'Resend code',
        skipUploadingFile: 'Please make sure to upload the document at a later stage',
        successUploadHospitalDoc: 'Your document was updated successfully'
    },

    rules: {
        passwordLength: "The password confirmation does not match.",
        required: "Field is required",
        require3Char: "The field must more than one character at least.",
        checkAllFields: "Please check all fields",
        passwordReuire: "Password is require",
        fileSize: 'file size must not be more than 2 MB!'
    },

    dashboard: {
        inProgress: "In Progress",
        interview: "Interview",
        accept: "Accept",
        closed: "Closed",
        declined: "Declined",
        decline: "Decline",
        personalInfo: "Personal information",
        wantToReset: "Are you sure you want to reset your password?",
        degrees: "Degrees",
        specializations: "Specializations",
        viewProfile: "View profile",
        resetPassword: "Reset Password",
        loading: "Loading",
        requestInfo: "Request info",
        enterEmailToGetCode: "Enter Email To Get Code",
        profile: "Profile",
        actions: "Actions",
        notStarted: "Not started",
        numberOfPositions: "Number of positions",
        healthCareProvider: "Health care provider",
        providerSpecialization: "Provider specialization",
        createNewRequest: "Create new request",
        allRequests: "All Requests",
        allInterviews: "All Interviews",
        hospitalName: "Hospital name",
        status: "Status",
        interviewTime: "Interview time",
        allDoctors: "All Doctors",
        specialist: "Specialist",
        location: "Location",
        date: "Date",
        doctorName: "Doctor's Name",
        allNotifications: "All Notifications",
        editPersonalInfo: "Edit personal info",
        showDocument: "Show Document",
        neededDocument: "Documents",
        pastDocuments: "Past Documents",
        education: "Education",
        workExperience: "Work & Experiences",
        selectChat: "Select Chat To Start Conversation",
        chats: "Chats",
        noChat: "No Chat Found",
        jobExperience: "Job Experience",
        addNewJobExperience: "Add New Job Experience",
        addNewDegree: "Add New Degree",
        contactInfo: "Contact Info",
        contactType: "Contact Type",
        cv: "CV",
        uploaded: "Uploaded",
        notUploaded: "Not Uploaded",
        degreeFile: "Degree File",
        portfolio: "Portfolio",

        socialMediaLinks: 'Social Media Links',
        socialMedia: 'Social Media',
        deleteDocument: 'Delete Document',
        profileCompleted: 'Your Profile Is Completed Successfully',
        goToDashboard: 'Back To Dashboard',
        interviews: "Interviews",
        notifications: "Notifications",
        requests: "Requests",
        ourDoctors: "Our Doctors",
        assignedoctors: 'Assigned doctors',
        documents: "Documents",
        viewDetails: "View details",
        next: "Next",
        closedAssignment: "Closed Assignment",
        orderAssignment: "Order Assignment",
        enterDateTime: "Enter Date & Time",
        startTime: "Start Time",
        endTime: "End Time",
        addMoreTime: "Add timing (Multiple)",
        noTimeSelected: "No times yet select date to add times",
        dateTime: "Date & Time",
        noDoctorsYet: 'No Doctors Assigned Yet.',
        back: 'Back',
        editFutureJob: 'Edit Future Job',
        confirmEditMeeting: 'Confirm /Edit Meeting',
        goToInterview: 'Go to interview',
        reschedule: 'Reschedule'
    },

    messages: {
        resetSuccess:
            "Message has sent successfully to your email please check your inbox..",
        loggedSuccess: "Logged in successfully",
        errorEmailPassword: "Email or password is incorrect",
    },

    footer: {
        terms: "Terms & Conditions",
        privacy: "Privacy Policy",
        ourOffices: "Our offices",
        copyRight: " MedicalJob. All rights reserved.",
    },
    side: {
        interviews: "Interviews",
        neededDocument: "Needed Documents",
        chats: "Chats",
        notifications: "Notifications",
        profile: "Profile",
        personalInfo: "Personal Info",
        degrees: "Degrees",
        jobExperience: "Job Experience",
        socialMedia: "Social Media",
        requests: "Requests",
        ourDoctors: "Our Doctors",
    },
    confirm:
        "Your Profile is under review. Once approved, we will send you a confirmation email within 24 hours.",
};
