var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar"},[_c('div',{staticClass:"max-width py-7"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"cursor-pointer",class:_vm.$vuetify.rtl ? 'ml-12': 'mr-12',attrs:{"width":"150px","src":require('@/assets/images/logo.png'),"alt":"logo"}})]),_c('div',{staticClass:"d-flex align-center justify-start"},_vm._l((_vm.links),function(link,i){return _c('div',{key:i},[(!link.select)?_c('router-link',{attrs:{"to":link.route}},[_c('span',{staticClass:"nav-link darktext--text font-16 d-inline-block mx-3",class:link.name == _vm.$route.name ? 'nav-link--active' : ''},[_vm._v(_vm._s(link.title))])]):_vm._e(),(link.select)?_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"nav-link d-inline-flex cursor-pointer navlinks",class:_vm.$route.name == 'Medical Institutions' ||
                    _vm.$route.name == 'Medical Professionals'
                      ? 'nav-link--active'
                      : '',on:{"click":function($event){_vm.openMenu = true}}},'div',attrs,false),on),[_c('span',{staticClass:"font-16 font-qs-m ls-1"},[_vm._v(_vm._s(link.title))]),_c('img',{attrs:{"width":"18px","src":require('@/assets/images/icons/arrow-down.svg')}})])]}}],null,true),model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[_c('v-list',{staticClass:"register-primary"},_vm._l((link.list),function(item,index){return _c('v-list-item',{key:index},[_c('router-link',{staticClass:"cursor-pointer",attrs:{"to":item.route,"tag":"div"}},[_c('span',{staticClass:"white--text font-16"},[_vm._v(_vm._s(item.name))])])],1)}),1)],1):_vm._e()],1)}),0)],1),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center cursor-pointer lang-btn",class:_vm.$vuetify.rtl ?'ml-3' : 'mr-3'},'div',attrs,false),on),[_c('img',{staticClass:"lang-img",attrs:{"width":"24px","height":"24px","src":require(`@/assets/images/${
                    _vm.$i18n.locale == 'en' ? 'en' : 'ar'
                  }.svg`)}}),_c('span',{staticClass:"font-14 font-500 d-inline-block ml-2 mr-3"},[_vm._v(_vm._s(_vm.$i18n.locale == "en" ? "English" : "العربية"))]),_c('img',{attrs:{"width":"20px","src":require('@/assets/images/icons/arrow-down.svg')}})])]}}]),model:{value:(_vm.linkActive),callback:function ($$v) {_vm.linkActive=$$v},expression:"linkActive"}},[_c('v-list',{staticClass:"px-2 list-lang"},[_c('div',{staticClass:"d-flex align-center cursor-pointer",on:{"click":_vm.switchLanguage}},[_c('img',{staticClass:"lang-img",attrs:{"width":"24px","height":"24px","src":require(`@/assets/images/${
                    _vm.$i18n.locale == 'en' ? 'ar' : 'en'
                  }.svg`)}}),_c('span',{staticClass:"font-14 font-500 d-inline-block ml-2 mr-3"},[_vm._v(_vm._s(_vm.$i18n.locale == "en" ? "العربية" : "English"))])])])],1),(!_vm.token && _vm.$route.name != 'Sign Contract')?_c('v-btn',{staticClass:"radius-10 ml-3 cursor-pointer",class:_vm.$vuetify.rtl ?'mr-3' : 'ml-3',attrs:{"color":"register-primary","elevation":"0","width":"200px","height":"45px"},on:{"click":function($event){return _vm.$router.push('/register/login')}}},[_c('span',{staticClass:"white--text font-700 font-18"},[_vm._v(_vm._s(_vm.$t("navbar.login")))])]):_vm._e(),(_vm.token)?_c('v-btn',{staticClass:"radius-10 hover-btn mx-3",attrs:{"outlined":"","color":"register-primary","elevation":"0","width":"200px","height":"45px"},on:{"click":function($event){return _vm.$router.push('/dashboard/interviews')}}},[_c('span',{staticClass:"register-primary--text font-700 font-18"},[_vm._v(_vm._s(_vm.$t("navbar.viewDashboard")))])]):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }