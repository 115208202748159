<template>
  <div >
    <desktop-nav :links="links" v-if="!isMd"></desktop-nav>
    <mobile-drawer :links="links" v-if="isMd"></mobile-drawer>
  </div>
</template>

<script>
import DesktopNav from './components/DesktopNav.vue';
import MobileDrawer from './components/Drawer.vue';

export default {
  components: { DesktopNav, MobileDrawer },
  data: (vm) => ({
    links: [
      { title: vm.$t("navbar.home"), route: "/", active: false, select: false, name: 'Home', },
      { title: vm.$t("navbar.aboutUs"), route: "/about", active: false, select: false, name: 'About', },
      {
        title: vm.$t("navbar.howItWorks"),
        route: "/how-it-works",
        active: false,
        select: true,
        list: [
          {
            name: vm.$t("navbar.institutions"),
            route: "/medical-institutions",
          },
          {
            name: vm.$t("navbar.professionals"),
            route: "/medical-professionals",
          },
        ],
      },
      { title: vm.$t("navbar.countries"), route: "/countries", active: false, select: false, name: 'Countries', },
      // { title: vm.$t("navbar.blogs"), route: "/blogs", active: false, select: false, name: 'Blogs', },
      { title: vm.$t("navbar.contactUs"), route: "/contact", active: false, select: false, name: 'Contact', },
    ],
  }),
  methods: {
  },
};
</script>

<style lang="scss">
</style>
