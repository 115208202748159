import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/style/main.scss";
import "./assets/style/_variables.scss";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";
import "./assets/style/rtl.scss";
// helper
import helper from "./utils/helper";
// base
import BaseInput from "@/components/base/Input";
import BaseSelect from "@/components/base/Select";
import BasePassword from "@/components/base/Password";
import BaseRegisterBtn from "@/components/base/RegisterBtn";
import LoadingSpinner from "@/components/global/Spinner";

// slick carousel
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_green.css';

import VueSignature from "vue-signature-pad";

Vue.use(VueSignature);
Vue.use(VueFlatPickr);
// i18n
import i18n from "./i18n";
import axios from "./plugins/axios";
import SkipBtn from "@/components/base/SkipBtn.vue";
Vue.prototype.$helper = helper;

Vue.component("base-input", BaseInput);
Vue.component("base-select", BaseSelect);
Vue.component("base-password", BasePassword);
Vue.component("base-register-btn", BaseRegisterBtn);
Vue.component("loading-spinner", LoadingSpinner);
Vue.component("slick-carousel", VueSlickCarousel);
Vue.component("base-skip-btn", SkipBtn);

Vue.use(axios);
Vue.use(VueFlatPickr);
Vue.config.productionTip = false;

Vue.mixin({
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isMd() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md
      );
    },
  },
});

Vue.use(require("vue-moment"));

// Vue.directive('click-outside', {
//   bind: function (el, binding, vnode) {
//     el.clickOutsideEvent = function (event) {
//       // here I check that click was outside the el and his children
//       if (!(el == event.target || el.contains(event.target))) {
//         // and if it did, call method provided in attribute value
//         vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener('click', el.clickOutsideEvent)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent)
//   },
// });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
