import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
  base: process.env.BASE_URL,
});

const DEFAULT_TITLE = "Medical";
router.beforeEach(async (to, from, next) => {
  Vue.nextTick(() => {
    document.title = "Medical - " + to.meta.title || DEFAULT_TITLE;
  });
  // check if logged
  let isLogin = localStorage.getItem("token");
  let role = localStorage.getItem("role");

  if (to.matched.some((record) => record.meta.requiresNoAuth)) {
    if (isLogin) {
      if (role == "doctor") {
        next({
          path: "/dashboard/interviews",
        });
      } else {
        next({
          path: "/dashboard/orders",
        });
      }
    } else {
      next();
    }
    return;
  }
console.log('to', to)
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.matched.some((record) => record.meta.dashboard)
  ) {
    // if not, redirect to login page.
    if (isLogin) {
      next();
    } else {
      next({
        path: "/register/login",
      });
    }
    return;
  }

  next();
});

export default router;
