<template>
  <div class="navbar">
    <div class="max-width py-7">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <!-- logo -->
          <router-link to="/">
            <img
              class="cursor-pointer "
              :class="$vuetify.rtl ? 'ml-12': 'mr-12'"
              width="150px"
              :src="require('@/assets/images/logo.png')"
              alt="logo"
            />
          </router-link>

          <!-- links -->
          <div class="d-flex align-center justify-start">
            <div v-for="(link, i) in links" :key="i">
              <router-link v-if="!link.select" :to="link.route">
                <span
                  class="nav-link darktext--text font-16 d-inline-block mx-3"
                  :class="link.name == $route.name ? 'nav-link--active' : ''"
                  >{{ link.title }}</span
                >
              </router-link>

              <v-menu v-if="link.select" v-model="openMenu" bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="nav-link d-inline-flex cursor-pointer navlinks"
                    @click="openMenu = true"
                    :class="
                      $route.name == 'Medical Institutions' ||
                      $route.name == 'Medical Professionals'
                        ? 'nav-link--active'
                        : ''
                    "
                  >
                    <span class="font-16 font-qs-m ls-1">{{ link.title }}</span>
                    <img
                      width="18px"
                      :src="require('@/assets/images/icons/arrow-down.svg')"
                    />
                  </div>
                </template>

                <v-list class="register-primary">
                  <v-list-item v-for="(item, index) in link.list" :key="index">
                    <router-link
                      :to="item.route"
                      tag="div"
                      class="cursor-pointer"
                    >
                      <span class="white--text font-16">{{ item.name }}</span>
                    </router-link>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>

        <!-- buttons -->
        <div class="d-flex align-center justify-end">
          <!-- languages -->
          <v-menu offset-y left v-model="linkActive">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex align-center cursor-pointer lang-btn"
                :class="$vuetify.rtl ?'ml-3' : 'mr-3'"
                v-bind="attrs"
                v-on="on"
              >
                <img
                  class="lang-img"
                  width="24px"
                  height="24px"
                  :src="
                    require(`@/assets/images/${
                      $i18n.locale == 'en' ? 'en' : 'ar'
                    }.svg`)
                  "
                />
                <span class="font-14 font-500 d-inline-block ml-2 mr-3">{{
                  $i18n.locale == "en" ? "English" : "العربية"
                }}</span>
                <img
                  width="20px"
                  :src="require('@/assets/images/icons/arrow-down.svg')"
                />
              </div>
            </template>
            <v-list class="px-2  list-lang">
              <div
                class="d-flex align-center cursor-pointer"
                @click="switchLanguage"
              >
                <img
                  class="lang-img"
                  width="24px"
                  height="24px"
                  :src="
                    require(`@/assets/images/${
                      $i18n.locale == 'en' ? 'ar' : 'en'
                    }.svg`)
                  "
                />
                <span class="font-14 font-500 d-inline-block ml-2 mr-3">{{
                  $i18n.locale == "en" ? "العربية" : "English"
                }}</span>
              </div>
            </v-list>
          </v-menu>
          <!-- create account -->
          <!-- <v-btn
            outlined
            color="register-primary"
            elevation="0"
            width="200px"
            height="45px"
            class="radius-10 hover-btn mx-3"
            @click="$router.push(token ? '/dashboard/interviews' : '/register')"
          >
            <span class="register-primary--text font-700 font-18">{{
              $t(token ? "navbar.viewDashboard" : "navbar.createAccount")
            }}</span>
          </v-btn> -->

          <!-- login page -->
          <v-btn
            color="register-primary"
            elevation="0"
            width="200px"
            height="45px"
            class="radius-10 ml-3 cursor-pointer"
            :class="$vuetify.rtl ?'mr-3' : 'ml-3'"
            @click="$router.push('/register/login')"
            v-if="!token && $route.name != 'Sign Contract'"

          >
            <span class="white--text font-700 font-18">{{
              $t("navbar.login")
            }}</span>
          </v-btn>

          <!-- <v-btn
            color="#FF7373"
            dark
            elevation="0"
            outlined
            class="radius-10"
            width="150px"
            height="45px"
            @click="logoutHandler"
            v-if="token"
          >
            <span class="font-16">{{ $t("forms.logout") }}</span>
          </v-btn> -->
          <v-btn
            outlined
            color="register-primary"
            elevation="0"
            width="200px"
            height="45px"
            class="radius-10 hover-btn mx-3"
            @click="$router.push('/dashboard/interviews')"
            v-if="token"
          >
            <span class="register-primary--text font-700 font-18">{{
              $t("navbar.viewDashboard")
            }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    linkActive: false,
    openMenu: false,
  }),
  computed: {
    token() {
      return localStorage.getItem("token");
    },
  },
  methods: {
    logoutHandler() {
      this.$store.dispatch("logout");
    },
    switchLanguage() {
      if (this.$i18n.locale == "en") {
        this.$vuetify.rtl = true;
        this.$i18n.locale = "ar";
        localStorage.setItem("language", "ar");
      } else {
        this.$vuetify.rtl = false;
        this.$i18n.locale = "en";
        localStorage.setItem("language", "en");
      }
      this.$router.go();
    },
  },
};
</script>

<style lang="scss">
.navbar {
  position: relative;
  z-index: 999;
  .lang-img {
    border-radius: 50%;
  }
  .nav-link {
    border-bottom: 2px solid transparent;
    transition: 0.3s;

    &--active {
      border-bottom: 2px solid $register-primary;
    }
  }

  .lang-btn{
    border-radius: 6px;
    background: rgba($register-primary, 0.08);
    padding: 10px;
    transition: 0.3s;
    
    &:hover{
      background: rgba($register-primary, 0.28);
      
    }
  }
}
.list-lang{
  background: rgba($register-primary, 0.08) !important;
  transition: 0.3s !important;
    
  &:hover{
    background: rgba($register-primary, 0.28) !important;
    
  }
}
.v-list-item {
  min-height: 35px !important;
}
</style>
