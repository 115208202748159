<template>
  <div class="register-btn">
    <v-btn
      elevation="0"
      height="40px"
      color="register-primary"
      block
      :type="type"
      width="100px"
      :disabled="loading"
      @click="$emit('click')"
    >
      <span class="font-16 font-600 white--text" v-if="!loading">{{
        title
      }}</span>
      <span v-if="loading"><loading-spinner></loading-spinner></span>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
    .theme--light.v-btn.v-btn--disabled.v-btn--has-bg{
  background-color: $register-primary !important;
}
</style>
