import Vue from "vue";
import axios from "axios";
import store from "../store";
Vue.prototype.$baseURL = "https://backend.medicaljob.me/api";
//Vue.prototype.$baseURL = "http://127.0.0.1:8000/api";

axios.defaults.headers.common = {
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  Lang: localStorage.getItem("language"),
};
axios.defaults.baseURL = `${Vue.prototype.$baseURL}/`;

window.axios = axios;
Vue.prototype.$axios = axios;
export default async () => {
  // show loader in each request

  window.axios.interceptors.request.use(
    (confiq) => {
      if (confiq.method == "post") {
        store.dispatch("loaderAxios", true);
      }
      return confiq;
    },
    (erorr) => {
      return erorr;
    }
  );

  window.axios.interceptors.response.use(
    function (response) {
      if (response.config.method == "post") {
        store.dispatch("loaderAxios", false);
      }
      return response;
    },
    async function (error) {
      console.log('error', error)
      store.dispatch("loaderAxios", false);
      if (error.response.status == 500) {
        store.dispatch("showSnack", {
          text: "Internal server error please try again later",
          color: "error",
        });
      }
      store.dispatch("loading", false);
      // response with status code > 200 is here
      return error.response.data;
    }
  );
};
