export default {
    global: {
        welcome: 'مرحبا بكم في ميديكال جوب',
        sign: 'لتسريع عملية تفعيل حسابك، نرجو منك التعاون في استكمال الخطوات التالية:' +
            '\n\n1. مراجعة العقد\n' +
            '2. أضف رقم جواز سفرك\n' +
            '3. توفير التوقيع الرقمي',
        hospitalSign: 'لتسريع عملية تفعيل حسابك، نرجو منك التعاون في استكمال الخطوات التالية:' +
            '\n\n1. مراجعة العقد\n' +
            '2. أضف المعلومات المتبقية\n' +
            '3. توفير التوقيع الرقمي',

        viewContract: 'عرض العقد ',
        signContract: 'توقيع العقد ',
        upload: 'رفع',
        download: 'تحميل',
        verificationCode: " تم إرسال رمز التحقق",
        GoToDashboard: "الذهاب إلى لوحة القيادة",
        GoToHome: "العودة إلى الصفحة الرئيسية",
        close: "إغلاق",
        generalError: 'حدث خطأ عام',
        yes: "نعم",
        no: "لا"
    },
    navbar: {
        home: "الصفحة الرئيسية ",
        howItWorks: "كيف تعمل ",
        countries: "البلدان",
        institutions: "المؤسسات الطبية ",
        professionals: "المهن الطبية ",
        blogs: "المقالات",
        aboutUs: "نبذة عنا",
        contactUs: "تواصل معنا",
        createAccount: "إنشاء حساب",
        login: "تسجيل الدخول / إنشاء حساب",
        viewDashboard: "عرض قائمة المعلومات ",
    },

    homepage: {
        headerTitle: "عرض الفرص الجديدة المتاحة",
        headerDesc:
            "خطوات قليلة تفصلك عن إيجاد فرصتك المثالية التي تناسب مجال اختصاصك ",
        exploreRegion:
            " تصفّح واكتشف المجالات الطبيّة المتاحة امامك في منطقة الشرق الأوسط وشمال افريقيا  ",
        welcomeHome:
            "إذا كنت من الاختصاصيين ذوي الكفاءة العالية، فهنا مكانك الأمثل أهلًا وسهلًا بك  !",
        showMoreCountries: "عرض مزيد من البلدان ",
        seeYou: "نسعد بابتسامتك ",
        aboutDesc: `
     لطالما حرصت وكالتنا على الانتقاء في اختيار فريق عمل متكامل، يتميّز بمهارات عالية وخبرات واسعة تشمل مناطق مختلفة منها الشرق الأوسط وشمال افريقيا، وذلك بهدف تعزيز إيجاد أفضل الفرص لمستخدمينا. تمكنت وكالتنا من دخول سوق العمل بشكل احترافي، حيث وفّرت العديد من الوظائف الشاغرة للأطباء اللبنانيون خاصةً في ظل الأزمة الاقتصادية التي شهدتها البلاد. فهذه كانت نقطة انطلاقنا إلى الدول العربية الكبرى حيث ينبثق جوهر وكالتنا على تعزيز التعاون وإيجاد الفرص المناسبة لأصحاب الاختصاص. مبادئنا مبنيّة على المصداقية والاحترافية فالخطوات المطلوبة سهلة ومباشرة، أمّا في حال حدوث أي عقبة في عملية التوظيف فقد طورنا نظام حديث يسمح بحل جميع المشاكل ويسهل مسار عمل كل من الأطباء والمرضى. رؤيتنا واضحة، فنحن نطمح أن نصبح المنصة الطبية الرائدة في مجال التوظيفات على صعيد المنطقة، من خلال نظامنا الواعد والمبسط لجميع مستخدميه. أمّا مهمتنا فهي توظيف طاقم عمل محترف يسعى جاهدًا لتقديم أفضل الفرص المتاحة في المنطقة لتحاكي تطلعات أطبائنا ذوي المهارات العالية 
    `,
        clientRecommendations: "توصيات العملاء ",

        exposedNew: "اكتشف المزيد",
        opportunities: "الفرص الجديدة",
        getThePerfectJob: "لنبدأ",
        countriesTitle: 'اكتشف المجال الطبي في منطقة الشرق الأوسط وشمال إفريقيا!',
        countriesTitleDesc: 'منزل ترحيبي للمهنيين الطبيين المؤهلين تأهيلاً عالياً!',
        showCountriesDetails: 'إظهار تفاصيل الدولة',
        countryDetailsTitle: "- مؤشر جودة الحياة",
    },

    completeProfile: {
        welcomeDescription: "نريدك أن تكون ناجحًا وأن تجد وظيفة أحلامك قريبًا،" +
            " وأحد أهم أدوات النجاح هو ملفك الشخصي. على مر السنين،" +
            " وجدنا أن المرشحين الأكثر نجاحًا لديهم ملفات تعريف كاملة" +
            " ودقيقة وترسم صورة مقنعة لخدماتهم ومهاراتهم وإنجازاتهم" +
            ". يرجى إكمال ملف التعريف الخاص بك للحصول على فرصة أفضل للتوظيف.",
        start: "إبدأ",
        getStarted: "البداية",
        personalInfo: "المعلومات الشخصية",
        jobExperience: "خبرات العمل",
        degrees: "الشهادات",
        socialMedia: "مواقع التواصل الإجتماعي",
        futureJob: "العمل المستقبلي",
        contactInfo: "معلومات التواصل"

    },

    howItWorks: {
        howItWorks: "كيف تعمل",
        medicalInstitutions: "المؤسسات الطبية",
        medicalProfessionals: "محترفين طبيا",
        firstStep: "الخطوة الأولى",
        secondStep: "الخطوة الثانية",
        thirdStep: "الخطوة الثالثة",
        fourthStep: "الخطوة الرابعة",
        professionals: {
            firstStepSentense:
                "سجل التفاصيل اللازمة وتحميل الدرجات و / أو شهادة (شهادات) العمل.",
            secondStepSentense:
                "بعد التحقق من حسابك بواسطة المنصة ، سيبدأ فريقنا عملية التوظيف ويبحث عن تطابق مناسب لك بين المؤسسات الطبية الأعضاء.",
            thirdStepSentense:
                "تتبع التقدم المحرز في طلبك. بعد أن يتم اختياره من قبل إدارة التوظيف، وبمرحلة اخرى إجراء المقابلات.",
            fourthStepSentense:
                "عند الانتهاء من المقابلة وقبولك ، قم بتحميل جميع المستندات اللازمة من خلال منصتنا.",
        },
        institutions: {
            firstStepSentense: "\"هل أنت تمثل مؤسسة طبية؟ إليك كيف يمكننا مساعدتك.\"",
            secondStepSentense:
                "تسجيل البيانات اللازمة وتحميل أوراق الشركة الرسمية.",
            thirdStepSentense:
                "بعد أن يتم التحقق من حسابك، يمكنك البدء في طلب الملفات الشخصية للمهنيين الطبيين.",
            fourthStepSentense:
                "يبدأ فريق مديكال جوب فحص الملفات الشخصية للمرشحين المناسبين ويعلمك بمجرد تحديد المطابقات.\n" +
                "من خلال نظامنا الأساسي ، يمكنك جدولة اجتماعات الفيديو مع المرشحين المختارين وتغيير حالة المحترف المعين إلى مقبول.",
        },
    },

    aboutpage: {
        aboutus: "نبذة عنّا ",
        perfectJob: "احصل على الوظيفة الأمثل ",
        whoWeAre: "من نحن؟ ",
        headDesc:
            "يتكون فريقنا من مجندين متمرسين أقاموا علاقات قوية مع العديد من المؤسسات الطبية في جميع أنحاء منطقة الشرق الأوسط وشمال إفريقيا. استلهمت وكالتنا في البداية من مساعدة المهنيين الطبيين اللبنانيين في تأمين فرص عمل في دول عربية متنوعة.",

        ourAim: "هدفنا ",
        ourAimDesc:
            " نهدف ونتطلع دائمًا إلى تطوير وتعزيز شبكتنا وذلك لمساعدة أطبائنا الأكفاء في إيجاد الوظيفة المناسبة لهم في منطقة الشرق الاوسط وشمال افريقيا، حيث نقوم بدور الوسيط بينهم وبين المؤسسات الطبية في الخارج ",
        ourVision: "رؤيتنا",
        ourVisionDesc:
            "نحن نطمح أن نغدو المنصة الطبية الرائدة في مجال التوظيفات على صعيد المنطقة، في حين يمكن لمستخدمينا الاعتماد على وكالتنا لإيجاد فرص مناسبة تحاكي خبراتهم ",
        ourMission: "مهمتنا ",
        ourMissionDesc:
            " يمكنكم الاعتماد علينا في عرض أفضل الوظائف الطبية الشاغرة في مختلف المؤسسات، حيث يتميّز نظامنا بسهولة تصفحه وتحديثه المستمر لضمان عرض أفضل الفرص المتاحة على صعيد المنطقة. ",
    },

    userTypes: {
        joinOurNetwork: "انضم إلى شبكتنا   !",
        signup: "اشترك",
        joinUs: "انضم لنا ",
        professional: "المهن طبية ",
        institution: "مؤسسات طبية ",
        asHospital: "كمؤسسة طبية ",
        asHospitalDesc: "انضم إلينا كعيادة أو مستشفى لإيجاد أطباء أكفاء على الفور ",
        asDoctor: "كطبيب مختص  ",
        asDoctorDesc: "انضم إلينا كطبيب أو ممرض لتحصل على الوظيفة المثالية فورًا ",
    },

    blogsPage: {
        ourBlogs: "مقالاتنا ",
        showMoreCountries: "عرض بعض البلدان ",
    },

    getInTouch: {
        getInTouch: " ابقى على تواصل ",
        howCanWeHelp: "كيف بإمكاننا مساعدتك ?",
        contactUsNow: "إتصل بنا الآن",
        fillForm: "املأ الاستمارة أو اترك رسالة بريد الكتروني  📨",
    },

    heroPage: {
        yourType: "ماهو النموذج الخاص بك ?",
        loginNow: " سجل الدخول الآن ",
        signup: "اشترك ",
        asHospital: "كموؤسسة طبية ",
        asDoctor: "كطبيب اختصاصي ",
        joinUs: "انضم لنا ",
        help: "المساعدة ",
        privacy: "الخصوصية ",
        legal: "قانوني ",
        terms: "الشروط ",
        alreadyAccount: "يوجد لديك حساب ؟",
    },

    forms: {
        inputRequired: 'الحقل مطلوب',
        passwordValidation: 'يجب أن تحتوي كلمات المرور على 6 أحرف على الأقل، بما في ذلك حرف كبير واحد ورقم واحد على الأقل.',
        any: 'أي شيئ',
        done: 'تم',
        hospital: "المستشفى ",
        fullName: "الاسم بالكامل ",
        email: "البريد الالكتروني",
        required: "خانة إلزامية ",
        emailRquired: "البريد الالكتروني مطلوب ",
        emailValid: "يجب ان يكون البريد الالكتروني صحيحًا ",
        yourMessage: "رسالتك ",
        typeMessage: "اكتب رسالتك",
        send: "إرسال ",
        createAccount: "إنشاء حساب ",
        noAccount: "ليس لديك حساب ؟",
        forgetPassword: "لقد نسيت كلمة المرور ؟ ",
        password: "كلمة المرور ",
        confirmPassword: "تأكيد كلمة المرور ",
        login: "تسجيل الدخول ",
        createNewPassword: "اختار كلمة المرور الجديدة .",
        enterPassword: "إدخال كلمة المرور ",
        createPassword: "انشاء كلمة المرور ",
        create: "انشاء ",
        welcome: "أهلًا وسهلًا بكم 👋",
        createYourAccount: "أنشئ حساب لك ",
        next: "التالي ",
        checkEmail:
            "لقد تم إرسال رمز تحقق لبريدك الالكتروني، الرجاء التحقق وتأكيد البريد  ",
        verifyAccount: "تأكيد الحساب .",
        verificationCode: "رمز التأكيد ",
        enterVerificationCode: "يرجى إدخال رمز التحقق",
        verify: "تأكيد",
        lastThing: "الخطوة الأخيرة:",
        downloadContract: "تحميل العقد ",
        uploadFile: "تحميل الملف ",
        uploadSignedContract: "تحميل العقد الموقّع ",
        businessRegistrationDocuments: "وثائق تسجيل الشركة ",
        clinic: "عيادة ",
        doctor: "طبيب",
        representativeName: "إسم المُنتدَب",
        clinicName: " إسم المؤسسة الطبية  ",
        medicalInstitutionType: "نوع المؤسسة الطبية ",
        fillForm: "الرجاء ملء النموذج المرفق",
        welcomeDoctor: "أهلًا وسهلًا بك أيها الطبيب 👋  ",
        formAlso: "يرجى إكمال إدخال معلوماتك الشخصية",
        phonenumber: "رقم الهاتف ",
        phone: "الهاتف",
        country: "البلد ",
        countryCode: "الرقم الدولي",
        selectCoutry: "اختار البلد ",
        city: "المدينة",
        selectCity: "اختار المدينة ",
        edit: "تعديل ",
        addNew: "إضافة المزيد",
        jobExperience: "الخبرة العملية ",
        Institute: "المؤسسة ",
        jobPosition: "منصب الوظيفة ",
        fromDate: "إبتداءًا من تاريخ",
        require: "إلزامية ",
        toDate: "إلى تاريخ",
        save: "حفظ ",
        add: "إضافة",
        discard: "حذف ",
        cancel: "إلغاء ",
        specialities: "تخصصات  ",
        gender: "الجنس",
        nationality: "الجنسية",
        assignedAt: "التعيين في ",
        birthDate: "تاريخ الولادة ",
        firstName: "الاسم الأول",
        lastName: "الاسم الأخير",
        logout: " تسجيل الخروج ",
        continue: "متابعة ",
        update: "تحديث ",
        doctorRecommendedCountry: "مواقع المهنة المستقبلية ",
        expectedSalary: " الدخل المتوقع بالدولار الأميركي ",
        countryOfResidence: " مكان الإقامة ",
        futureJobType: " نوع المهنة المستقبلية ",
        male: "ذكر",
        female: "أنثى",
        search: "بحث",
        sendMessage: "إرسال رسالة",
        customer: "عميل",
        addOrDrag: "اضغط هنا لإضافة المزيد من الوثائق",
        addNewRequest: "إضافة طلب",
        specializations: "تخصصات ",
        healthcareProviderType: "نوع الرعاية الصحية المعطاة",
        experience: "الخبرة",
        jobType: "نوع العمل",
        jobUrgency: "ضرورة العمل",
        educationLevel: "المستوى العلمي",
        countryOfEducation: "مكان الدراسة",
        numberOfPositionNeeded: "عدد المناصب المطلوبة",
        additionalInformation: "معلومات إضافية",
        dataCheck: "تحقق من المعلومات",
        yes: "نعم",
        no: "كلا",
        howDidYouHead: "كيف سمعت عنا؟ ",
        name: "الاسم",
        editPersonalInformation: "تعديل المعلومات الشخصية",
        futureJobLocation: "موقع العمل المستقبلي",
        expectedMonthlySalary: "الدخل المتوقع شهريًا بالدولار الأميركي",
        delete: "حذف",
        fieldOfStudyMajor: "مجال تخصصك",
        universityOrInstitutionCountry: "اسم الجامعة أو المؤسسة ",
        graduationDateFrom: "تاريخ التخرج من",
        graduationDateTo: "تاريخ التخرج إلى",
        degree: "الشهادة العلمية",
        editDegree: "تعديل الشهادة العلمية",
        wantToDelete: "هل ترغب بحذف هذه؟ ?",
        cv: "السيرة الذاتية",
        contactType: "طريقة التواصل",
        degreeFile: "ملف الشهادة العلمية",
        portfolio: "الملف الخاص بك",
        editSocialMediaLinks: "تعديل روابط التواصل الاجتماعي",
        editContactInfo: "تعديل بيانات الاتصال",
        selectAll: "اختار الكل",
        completeProfileHint: "الضغط هنا لتحديث ملفك الشخصي",
        otherNationality: "جنسيات أخرى (اختياري)",
        yearsOfExperience: "سنوات الخبرة",
        saveContinue: "حفظ ومتابعة",
        profileCompleted: "تم اتمام بيانات الحساب الشخصي بنجاح",
        message: "رسالة ",
        clickToView: 'اضغط لعرض الملف',
        passportNum: 'الرجاء إدخال رقم جواز السفر الخاص بك',
        drawToSign: 'الرجاء التوقيع عليها إلكترونيًا باستخدام الماوس أو إصبعك',
        number: 'رقم الجواز',
        dataCheckCountry: 'فحص البيانات في دولة',
        dataCheckFile: 'ملف فحص البيانات',
        signerName: 'اسم الموقع',
        signerPosition: 'منصبك في العمل',
        companyRegistrationNumber: 'رقم تسجيل الشركة',
        skipAndUploadLater: 'تخطي وتحميل لاحقًا',
        codeResend: 'إعادة إرسال الرمز',
        skipUploadingFile: 'يرجى التأكد من تحميل المستند في مرحلة لاحقة',
        successUploadHospitalDoc: 'تم تحديث المستند الخاص بك بنجاح'
    },

    rules: {
        passwordLength: " كلمة المرور المؤكدة غير مطابقة ",
        required: " خانة إلزامية ",
        require3Char: " يجب أن يكون الحقل أكثر من حرف واحد على الأقل. ",
        checkAllFields: " الرجاء التأكد من جمیع الخانات أعلاه ",
        passwordReuire: " وجوب ادخال كلمة المرور",
        fileSize: 'الملف كبير جدا يجب ان يكون اقل من 2 ميغابايت! '
    },

    dashboard: {
        inProgress: " جاري العمل ",
        interview: " المقابلة  ",
        accept: "قبول ",
        closed: "إغلاق ",
        declined: "رُفضت ",
        decline: "رفض ",
        personalInfo: "معلومات شخصية",
        wantToReset: "هل انت متأكد من طلب تغيير كلمة المرور؟",
        degrees: " الشهادات العلمية ",
        specializations: " التخصصات ",
        viewProfile: " عرض الملف الشخصي ",
        resetPassword: " إعادة تعيين كلمة المرور ",
        requestInfo: "طلب معلومات ",
        enterEmailToGetCode: " أدخل البريد الالكتروني لتلقي الرمز ",
        profile: "الملف الشخصي ",
        actions: "الإجراءات ",
        notStarted: "لم تبدأ بعد",
        numberOfPositions: "عدد المناصب",
        healthCareProvider: "مصدر الرعاية الصحية",
        providerSpecialization: "تخصصات الراعي",
        createNewRequest: "إنشاء طلب جديد",
        allRequests: "جميع الطلبات",
        allInterviews: "جميع المقابلات",
        hospitalName: "اسم المستشفى",
        status: "الحالة",
        interviewTime: "موعد المقابلة",
        allDoctors: "جميع الأطباء",
        specialist: "التخصص",
        location: "الموقع",
        date: "التاريخ",
        doctorName: "اسم الطبيب",
        allNotifications: "جميع الإشعارات",
        editPersonalInfo: "تعديل المعلومات الشخصية",
        showDocument: " عرض الملف",
        neededDocument: "الملفات ",
        pastDocuments: "الملفات السابقة",
        education: "التحصيل العلمي",
        workExperience: "الخبرات والعمل",
        selectChat: "انقر على الدردشة لبدء محادثة",
        chats: "الدردشات",
        noChat: "لم نعثر على دردشة",
        jobExperience: "الخبرة العملية",
        addNewJobExperience: "إضافة خبرة عملية جديدة",
        addNewDegree: "إضافة شهادة جديدة",
        contactInfo: "معلومات للتواصل",
        contactType: "طريقة التواصل",
        cv: "السيرة الذاتية",
        uploaded: "حُمِلت بنجاح",
        notUploaded: "لم يتم التحميل",
        degreeFile: "ملف الشهادة العلمية",
        portfolio: "الملف الخاص بك",
        socialMediaLinks: "روابط التواصل الاجتماعي",
        socialMedia: "التواصل الاجتماعي",
        deleteDocument: "حذف الملف",
        goToDashboard: "العودة الى قائمة المعلومات ",
        interviews: "المقابلات",
        notifications: "الاشعارات",
        requests: "الطلبات",
        ourDoctors: "أطبائنا",
        assignedoctors: "الأطباء المعينون",
        documents: "الملفات",
        viewDetails: "عرض التفاصيل",
        next: "التالي",
        closedAssignment: "مهمة مغلقة",
        orderAssignment: "طلب التنازل",
        enterDateTime: "ادخل التاريخ & الوقت",
        startTime: "وقت البدء",
        endTime: "وقت الانتهاء",
        addMoreTime: "اضافة المزيد من الاوقات",
        noTimeSelected: "لم يتم تحديد وقت يرجى اختيار وقت واحد على الاقل",
        dateTime: "التاريخ والوقت",
        noDoctorsYet: 'لايوجد دكتور محدد بعد.',
        back: 'عودة',
        editFutureJob: 'تعديل العمل المستقبلي',
        confirmEditMeeting: 'موافقة / تعديل المقابلة',
        goToInterview: 'انتقال الى المقابلة',
        reschedule: 'اعادة تعيين الوقت'
    },

    messages: {
        resetSuccess:
            " لقد تم إرسال رسالة إلى بريدك الالكتروني، يرجى التأكد منه في بريدك الوارد ",
        loggedSuccess: "تم تسجيل الدخول بنجاح ",
        errorEmailPassword: "البريد الالكتروني أو كلمة المرور غير صحيحة",
    },

    side: {
        interviews: "المقابلات",
        neededDocument: "المستندات المطلوبة",
        chats: "المحادثات",
        notifications: "الإشعارات",
        profile: "الملف الشخصي",
        personalInfo: "الحساب الشخصي",
        degrees: "الشهادات",
        jobExperience: "الخبرة المهنية",
        socialMedia: "التواصل الاجتماعي",
        requests: "الطلبات",
        ourDoctors: "أطبائنا ",
    },

    footer: {
        terms: "المصطلحات و الشروط",
        privacy: "سياسة الخصوصية",
        ourOffices: "مكاتبنا ",
        copyRight: "جميع حقوق العمل الطبي محفوظة ",
    },
    confirm:
        "يتم الآن مراجعة ملفك، بعد التحقق من المعلومات وقبول الطلب سنقوم بمراسلتك لتأكيد القبول وذلك في غضون ٢٤ ساعة.",
};
