const routes = [
  {
    path: "/",
    name: "Home",
    meta: { dashoard: false, title: "Home" },
    component: () => import("../views/home/Index"),
  },
  {
    path: "/about",
    name: "About",
    meta: { dashoard: false, title: "About" },
    component: () => import("../views/about/Index"),
  },
  {
    path: "/countries",
    name: "Countries",
    meta: { dashoard: false, title: "Countries" },
    component: () => import("../views/countries/Index"),
  },
  {
    path: "/country/:id",
    name: "Country",
    meta: { dashoard: false, title: "Country" },
    component: () => import("../views/countries/id/Index"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: { dashoard: false, title: "Contact" },
    component: () => import("../views/contact/Index"),
  },
  {
    path: "/blogs",
    name: "Blogs",
    meta: { dashoard: false, title: "Blogs" },
    component: () => import("../views/blogs/Index"),
  },
  {
    path: "/medical-institutions",
    name: "Medical Institutions",
    meta: { dashoard: false, title: "Medical" },
    component: () => import("../views/how-it-works/medical-institutions/Index"),
  },
  {
    path: "/medical-professionals",
    name: "Medical Professionals",
    meta: { dashoard: false, title: "Medical" },
    component: () =>
      import("../views/how-it-works/medical-professionals/Index"),
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: () => import("../views/static-main/Privacy.vue"),
    meta: { dashoard: false, title: "Privacy" },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/static-main/Terms.vue"),
    meta: { dashoard: false, title: "Terms" },
  },
  {
    path: "/complete",
    name: "Complete",
    meta: { dashoard: false, title: "Complete" },
    component: () => import("../views/complete/Index"),
  },

  {
    path: "/sign-contract",
    name: "Sign Contract",
    meta: { dashoard: false, title: "Sign Contract" },
    component: () => import("../views/sign-contract/Index"),
  },
  {
    path: "/sign-contract-hospital",
    name: "Sign Contract Hospital",
    meta: { dashoard: false, title: "Sign Contract Hospital" },
    component: () => import("../views/sign-contract-hospital/Index"),
  },

  // resgister & signin
  {
    path: "/register/",
    name: "Registar",
    meta: { dashoard: false, requiresNoAuth: true, title: "Registar" },
    component: () => import("../views/register/Index.vue"),
    children: [
      {
        path: "",
        name: "Registar",
        meta: { dashoard: false, requiresNoAuth: true, title: "Registar" },
        component: () => import("../views/register/hero-page/Index.vue"),
      },
      {
        path: "login",
        name: "Registar",
        meta: { dashoard: false, requiresNoAuth: true, title: "Login" },
        component: () => import("../views/register/login/Index.vue"),
      },
      {
        path: "forget-password",
        name: "Registar",
        meta: {
          dashoard: false,
          leftstyle: true,
          requiresNoAuth: true,
          title: "Forget-password",
        },
        component: () => import("../views/register/forget-password/Index.vue"),
      },
      {
        path: "verification",
        name: "Registar",
        meta: {
          dashoard: false,
          leftstyle: true,
          requiresNoAuth: true,
          title: "Verification",
        },
        component: () => import("../views/register/verification/Index.vue"),
      },
      {
        path: "signup-clinic",
        name: "Registar",
        meta: { dashoard: false, requiresNoAuth: true, title: "Signup-clinic" },
        component: () => import("../views/register/signup-clinic/Index.vue"),
      },
      {
        path: "signup-doctor",
        name: "Registar",
        meta: { dashoard: false, requiresNoAuth: true, title: "Signup-doctor" },
        component: () => import("../views/register/signup-doctor/Index.vue"),
      },
    ],
  },


  // dashboard
  // {
  //   path: "/upload-contract",
  //   name: "Upload Contract",
  //   meta: {
  //     dashboard: false,
  //     requiresAuth: false,
  //     completeProfile: true,
  //     title: "dashoard",
  //   },
  //   component: () => import("../views/register/contract-page/Index.vue"),
  // },  
  {
    path: "/dashboard/",
    name: "dashoard",
   
    component: () => import("../views/dashboard/layout/Sidebar.vue"),
    children: [
      {
        path: "interviews",
        name: "interviews",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "interviews",
        },
        component: () =>
          import("../views/dashboard/views/interviews/Index.vue"),
      },
      {
        path: "orders",
        name: "orders",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Orders",
        },
        component: () => import("../views/dashboard/views/orders/Index.vue"),
      },
      {
        path: "order/:id/doctors",
        name: "orders",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Doctor",
        },
        component: () =>
          import("../views/dashboard/views/orders/assigned-doctors/Index.vue"),
      },
      {
        path: "orders/:id",
        name: "orders",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Order",
        },
        component: () =>
          import("../views/dashboard/views/orders/_id/Index.vue"),
      },
      {
        path: "order-assign/:id/doctors/:doctorID",
        name: "orders",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Assign-doctor",
        },
        component: () =>
          import("../views/dashboard/views/order-assign/Index.vue"),
      },
      {
        path: "our-doctors",
        name: "our-doctors",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Our-doctors",
        },
        component: () =>
          import("../views/dashboard/views/our-doctors/Index.vue"),
      },
      {
        path: "doctors/:id",
        name: "doctor",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Doctors",
        },
        component: () =>
          import("../views/dashboard/views/our-doctors/_id/Index.vue"),
      },
      {
        path: "documents",
        name: "documents",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Documents",
        },
        component: () => import("../views/dashboard/views/documents/Index.vue"),
      },
      {
        path: "chats",
        name: "chats",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Chats",
        },
        component: () => import("../views/dashboard/views/chats/Index.vue"),
      },
      {
        path: "notifications",
        name: "notifications",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Notifications",
        },
        component: () =>
          import("../views/dashboard/views/notifications/Index.vue"),
      },
      {
        path: "profile/degrees",
        name: "profile-Degrees",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Degrees",
        },
        component: () =>
          import("../views/dashboard/views/profile/degrees/Index.vue"),
      },
      {
        path: "profile/info",
        name: "profile-info",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Info",
        },
        component: () =>
          import("../views/dashboard/views/profile/info/Index.vue"),
      },
      {
        path: "profile/social-medias",
        name: "profile-social",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Social-media",
        },
        component: () =>
          import("../views/dashboard/views/profile/social-media/Index.vue"),
      },
      {
        path: "profile/experiences",
        name: "profile-experience",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Experiences",
        },
        component: () =>
          import("../views/dashboard/views/profile/job-experience/Index.vue"),
      },
      {
        path: "reset-password",
        name: "Registar",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Experiences",
        },
        component: () => import("../views/register/reset-password/Index.vue"),
      },
    ],
  },
  // dashboard
  {
    path: "/complete-profile/",
    name: "complete-profile",
    meta: {
      dashboard: true,
      requiresAuth: true,
      completeProfile: true,
      title: "dashoard",
    },
    component: () => import("../views/complete-profile/Index.vue"),
    children: [
      {
        path: "get-started",
        name: "get-started",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Get Started",
        },
        component: () =>
          import("../views/complete-profile/pages/get-started/Index.vue"),
      },
      {
        path: "personal-info",
        name: "personal-info",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Personal Info",
        },
        component: () =>
          import("../views/complete-profile/pages/personal-info/Index.vue"),
      },
      {
        path: "job-experience",
        name: "job-experience",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Job Experience",
        },
        component: () =>
          import("../views/complete-profile/pages/job-experience/Index.vue"),
      },
      {
        path: "degrees",
        name: "degrees",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Degrees",
        },
        component: () =>
          import("../views/complete-profile/pages/degrees/Index.vue"),
      },
      {
        path: "social-media",
        name: "social-media",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Socail Media",
        },
        component: () =>
          import("../views/complete-profile/pages/social-media/Index.vue"),
      },
      {
        path: "future-job",
        name: "future-job",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Future Job",
        },
        component: () =>
          import("../views/complete-profile/pages/future-job/Index.vue"),
      },
      {
        path: "contact-info",
        name: "contact-info",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Contact Info",
        },
        component: () =>
          import("../views/complete-profile/pages/contact-info/Index.vue"),
      },
      {
        path: "completed",
        name: "Completed",
        meta: {
          dashboard: true,
          requiresAuth: true,
          completeProfile: true,
          title: "Completed",
        },
        component: () =>
          import("../views/complete-profile/pages/completed/Index.vue"),
      },
    ],
  },
  // {
  //   path: "*",
  //   redirect: '/',
  // },
];

export default routes;
