<template>
  <div class="footer-app pt-14 pb-5 register-primary">
    <div class="max-width">
      <v-row>
        <v-col cols="12" md="3">
          <img :src="require('@/assets/images/logo-white.png')" alt="logo" />
          <div class="d-flex align-center mt-6 cursor-pointer" @click="openEmail">
            <img
              class="mr-3"
              :src="require('@/assets/images/icons/mail.svg')"
              alt=""
            />
            <span class="font-20 white--text d-inline-block"
              >info@medicaljob.me</span
            >
          </div>
          <!-- terms -->
          <span
            class="font-20 white--text d-block mt-4 cursor-pointer"
           
            @click="$router.push('/terms')"
            >{{ $t("footer.terms") }}</span
          >
          <!-- privacy -->
          <span
            class="font-20 white--text d-block mt-4 cursor-pointer"
            @click="$router.push('/privacy-policy')"
            >{{ $t("footer.privacy") }}</span
          >

          <div class="d-flex align-center mt-7">
            <v-icon
              class="cursor-pointer"
              :class="$vuetify.isRtl ? 'ml-4' : 'mr-4'"
              size="24px"
              color="white"
              v-for="(social, i) in socials"
              :key="i"
              @click="goToLink(social.url)"
              >{{ social.icon }}</v-icon
            >
          </div>
        </v-col>

        <v-col cols="12" md="4" offset-md="1">
          <span class="font-24 white--text font-700 mb-4 d-block">{{
            $t("footer.ourOffices")
          }}</span>

          <div
            class="d-flex align-center cursor-pointer"
            v-for="(location, i) in locations"
            :key="i"
            @click="goToLink(location.link)"
          >
            <img
              class="mx-3 mb-4"
              :src="require('@/assets/images/icons/location.svg')"
              alt=""
            />
            <div>
              <span class="font-20 white--text d-block">{{
                location.country
              }}</span>
              <p class="font-20 white--text">{{ location.address }}</p>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- copyight -->
      <div class="text-center mt-10">
        <p class="font-21 white--text mb-0">
          © {{ currentDate() }} {{ $t("footer.copyRight") }}
        </p>
      </div>
    </div>

    <div class="bg-img">
      <img
        width="100%"
        :src="require('@/assets/images/footer-bg.png')"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    socials: [
      {
        icon: "mdi-linkedin",
        url: "https://www.linkedin.com/company/medicaljobme",
      },
      {
        icon: "mdi-instagram",
        url: "https://www.instagram.com/medicaljobme",
      },
      {
        icon: "mdi-twitter",
        url: "https://twitter.com/MedicalJob2",
      },
      {
        icon: "mdi-facebook",
        url: "https://www.facebook.com/medicaljobme",
      },
    ],
    locations: [
      {
        country: "UAE",
        address: " Mazaya Business Avenue BB1, 3004, JLT, Dubai, UAE",
        link: 'https://goo.gl/maps/fJdxgRjyJxprpRbg7'
      },
      {
        country: "Lebanon",
        address: " 1st Floor, SKM Building. Zouk Mosbeh, Lebanon",
        link: 'https://goo.gl/maps/7GbxyinNeo8EEDiF8'
      },
      {
        country: "Kuwait (AlYousifi) ",
        address:
          " 16th Floor, Panasonic Tower, Fahad Al Salem Street Kuwait City, Kuwait",
          link: 'https://goo.gl/maps/gkqdmsYKgHASdMy58'
      },
    ],
  }),
  methods: {
    goToLink(url) {
      window.open(url);
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}`;
      return date;
    },
    openEmail(){
      window.location.href = "mailto:info@medicaljob.me";
    }
  },
};
</script>

<style lang="scss">
.footer-app {
  position: relative;
  .bg-img {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30%;
  }
}
</style>
